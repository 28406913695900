

















































































import { Component, Vue } from 'vue-property-decorator';
import { HomeContent } from '@/types/neuhaus-website/cms/pages/home';
import axios, { APIResponse } from '@/plugins/axios';
import AlertModule, { AlertType } from '@/store/modules/alert';

/**
 * CMSAdminHome component
 * 
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component({
    components: {
        'cms-page': () => import('@/components/cms/pages/CMSPage.vue'),
        'home-company-information-list': () => import('@/components/neuhaus-website/cms/admin/home/company-informations/HomeCompanyInformationList.vue'),
        'home-page-card-list': () => import('@/components/neuhaus-website/cms/admin/home/page-cards/HomePageCardList.vue')
    }
})
export default class CMSAdminHome extends Vue {
    // variable with form data
    private formData: HomeContent = {
        id: -1,
        title: 'N/A',
        companyInformations: {
            id: 0,
            rows: []
        },
        contentBox: {
            id: -1,
            backgroundColor: 'grey darken-3',
            color: 'white',
            content: 'N/A'
        },
        pageCards: []
    }

    // Boolean that defines wheather form is valid or not
    private formValid = false;

    /**
     * Function will be executed on component load
     * Fetches data with fetchPageContent function
     */
    private async created() {
        this.formData = await this.fetchPageContent();
    }

    /**
     * Resets the form validation
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private resetFormValidation() {
        if (this.$refs.form) {
            (this.$refs.form as Vue & { resetValidation: () => void }).resetValidation();
        }
    }

    /**
     * Fetches page content from API
     * 
     * @returns HomeContent
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private async fetchPageContent(): Promise<HomeContent> {
        try {
            const response = await axios.get<APIResponse<HomeContent>>('/cms/pages/home')

            if (response.data.status === 'error' || !response.data.data) {
                throw null;
            }

            return response.data.data;
        } catch (err) {
            return this.formData;
        }
    }

    /**
     * Saves form data
     * 
     * @author Kevin Danne <danne@skiba-procomputer.de>
     */
    private saveFormData() {
        axios.put<APIResponse>('/cms/pages/home', this.formData)
            .then(response => {
                if (response.data.status === 'success' && response.data.data) {
                    this.resetFormValidation();
                    this.$router.push({ name: 'cmsAdminPages' });
                }
                else if (response.data.status === 'error' && response.data.message) {
                    const errorMessage = this.$t('alerts.errors.' + response.data.message).toString();
                    AlertModule.showAlert({ 
                        type: AlertType.ERROR,
                        message: errorMessage 
                    });
                }
            });
    }
}
